import { axios } from "@/services/axios/config";

/**
 * API function to fetch proposals
 * @param payload 
 * @param config 
 * @returns { Promise }
 */
export const getProposals = (payload: any, config: any) => {
    return axios.post('quotes/proposal_list/?page_size=100', payload, config)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((err) => {
            return Promise.reject(err)
        })
}

/**
 * API function to fetch proposal details
 * @param config 
 * @returns { Promise }
 */
export const getProposalDetails = (config: any) => {
    return axios.get('quotes/quote_detail/', config)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((err) => {
            return Promise.reject(err)
        })
}

/**
 * API function to revise proposal
 * @param payload 
 * @param config 
 * @returns { Promise }
 */
export const reviseProposal = (payload: any, config: any) => {
    return axios.put('quotes/quote_revised/', payload, config)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((err) => {
            return Promise.reject(err)
        })
}

/**
 * API function to fetch sales conditions
 * @param param 
 * @returns { Promise }
 */
export const getSalesConditions = (param: any) => {
    return axios.get('quotes/get_sales_conditions/' + param)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((err) => {
            return Promise.reject(err)
        })
}

/**
 * API function to refuse proposal
 * @param payload 
 * @param config 
 * @returns { Promise }
 */
export const refuseProposal = (payload: any, config: any) => {
    return axios.put('quotes/refuse_proposal/', payload, config)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((err) => {
            return Promise.reject(err)
        })
}

/**
 * API function to initiate proposal signature
 * @param payload 
 * @param config 
 * @returns { Promise }
 */
export const initiateProposalSignService = (payload: any, config: any) => {
    return axios.post('quotes/initiate_proposal_sign/', payload, config)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((err) => {
            return Promise.reject(err)
        })
}

/**
 * API function to download signed proposal
 * @param payload 
 * @param config 
 * @returns { Promise }
 */
export const downloadContract = (payload: any, config: any) => {
    return axios.post('quotes/get_signed_pdf/', payload, config)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((err) => {
            return Promise.reject(err)
        })
}

/**
 * API function do signature operations in the backend
 * after client signs in iframe
 * @param payload 
 * @param config 
 * @returns { Promise }
 */
export const signProposal = (payload: any, config: any) => {
    return axios.post('quotes/sign_proposal/', payload, config)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((err) => {
            return Promise.reject(err)
        })
}