import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { navigate } from 'vike/client/router';
import { BusinessRequest } from "@/models/BusinessRequest";


export const useBusinessRequestStore = defineStore('business-request', () => {
    const blockingPageLeave = ref(false) // flag to avoid infinite loop on profile rerender when there are changes
    const onBusinessRequestPage = ref(false)
    const leaveWithoutSaving = ref(false)
    const hasChanges = ref(false)
    const isCreating = ref(true)
    const businessrequest_id: Ref<number | null> = ref(null)

    const businessRequest: Ref<BusinessRequest | null | undefined> = ref(null)
    const formData: Ref<any> = ref(null)
    const zipcode: Ref<any> = ref(null)

    const setHasChanges = (value: boolean) => {
        hasChanges.value = value
    }

    const clearData = () => {
        blockingPageLeave.value = false
        onBusinessRequestPage.value = false
        leaveWithoutSaving.value = false
        isCreating.value = true
        businessrequest_id.value = null
        zipcode.value = null
        businessRequest.value = null
        formData.value = null
    }

    const setData = (br: BusinessRequest | null | undefined, formdata: any) => {
        businessRequest!.value = br
        formData.value = formdata
    }

    function isFormDataEqual(formData: any, businessRequest: any) {
        return (
            formData.title === businessRequest.title &&
            formData.description === businessRequest.description &&
            formData.urgency === businessRequest.urgency &&
            formData.town === businessRequest?.town + (zipcode.value ? ' (' + zipcode.value + ')' : '')
        );
    }

    const handleSaveChangesBeforeLeaving = async (pageContext: any) => {
        if (formData.value !== null && businessRequest.value !== null && isCreating.value === false) {
            const isEqual = isFormDataEqual(formData.value, businessRequest.value)
            if (isEqual) {
                hasChanges.value = false
            }
        }
        const businessRequestStore = useBusinessRequestStore()
        if (businessRequestStore.hasChanges && !businessRequestStore.blockingPageLeave && businessRequestStore.onBusinessRequestPage) {
            if (window.event?.type === 'popstate') {
                if (!businessRequestStore.leaveWithoutSaving && businessRequestStore.hasChanges) {
                    const elem: any = document.getElementById('modalbutton');
                    elem?.click();
                    businessRequestStore.blockingPageLeave = true // set it to true to avoid infinite loop
                    if (businessRequestStore.isCreating === true) {
                        await navigate(`/cs/business-request?destination=${pageContext.urlPathname}`)
                    } else {
                        await navigate(`/cs/business-request-detail?id=${businessRequestStore.businessrequest_id}&destination=${pageContext.urlPathname}`)
                    }

                } else {
                    businessRequestStore.clearData()
                    businessRequestStore.hasChanges = false
                    businessRequestStore.$dispose()
                }
            } else {
                if (!businessRequestStore.leaveWithoutSaving && businessRequestStore.hasChanges) {
                    const elem: any = document.getElementById('modalbutton');
                    elem?.click();
                    businessRequestStore.blockingPageLeave = true // set it to true to avoid infinite loop

                    if (businessRequestStore.isCreating === true) {
                        await navigate(`/cs/business-request?destination=${pageContext.urlPathname}`)
                    } else {
                        await navigate(`/cs/business-request-detail?id=${businessRequestStore.businessrequest_id}&destination=${pageContext.urlPathname}`)
                    }
                } else {
                    businessRequestStore.clearData()
                    businessRequestStore.hasChanges = false
                    businessRequestStore.$dispose()
                }
            }
        }
    }

    return {
        handleSaveChangesBeforeLeaving,
        hasChanges,
        leaveWithoutSaving,
        onBusinessRequestPage,
        isCreating, zipcode,
        businessrequest_id, businessRequest, formData,
        clearData, setData,
        blockingPageLeave,
        setHasChanges,
    }
})