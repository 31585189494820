import moment from 'moment-timezone'
import { formatPriceToFrenchSystem, pad } from '@/utils/functions'
import { ProposalStatus } from '@/utils/enums'
import { IProposal } from './interfaces/interfaces'

/**
 * @fileoverview
 * This file uses interface merging to avoid redefining fields in the extended interface
 * To learn more about interface merging go to:
 * {@link https://www.typescriptlang.org/docs/handbook/declaration-merging.html}
 */

/**
 * Represents a quote
 * @class
 */
export interface Proposal extends IProposal {}
export class Proposal {
    /**
     * Create a quote
     * @constructor
     * @param {IProposal} proposalData
     * @example
     * const proposal = new Proposal({ id: 1, client_budget:... } : IProposal)
     */
    constructor(proposalData: IProposal) {
        Object.assign(this, proposalData)
    }

    /**
     * Return total_taxincl in french currency format
     * @example 2 500,58
     */
    public get formated_total_taxincl () : string {
        return formatPriceToFrenchSystem(this.total_taxincl!)
    }

    /**
     * Return total_taxexcl in french currency format
     * @example 2 500,58
     */
    public get formated_total_taxexcl () : string {
        return formatPriceToFrenchSystem(this.total_taxexcl!)
    }

    /**
     * Return total_taxincl_discounted in french currency format
     * @example 2 500,58
     */
    public get formated_total_taxincl_discounted (): string {
        return formatPriceToFrenchSystem(this.total_taxincl_discounted!)
    }

    /**
     * Return total_taxexcl_discounted in french currency format
     * @example 2 500,58
     */
    public get formated_total_taxexcl_discounted (): string {
        return formatPriceToFrenchSystem(this.total_taxexcl_discounted)
    }

    /**
     * Return discount_value in french currency format
     * @example 2 500,58
     */
    public get formated_discount_value () : string {
        return formatPriceToFrenchSystem(this.discount_value!)
    }

    /**
     * Return total_tax in french currency format
     * @example 2 500,58
     */
    public get formated_total_tax () : string {
        return formatPriceToFrenchSystem(this.total_tax!)
    }

    /**
     * Return the negative value of discount_amount in french currency format
     * @example 2 500,58
     */
    public get formated_discount_amount () : string {
        return '- ' + formatPriceToFrenchSystem(this.discount_amount)
    }

    /**
     * Returns the accepted_payment_modes formated with '\<br>' as line break
     * @type { String }
     */
    public get formated_accepted_payment_modes () : string {
        if (!this.accepted_payment_modes) {
            return ''
        }
        return this.accepted_payment_modes.replace(/\n/g, '<br>')
    }

    /**
     * Returns the additional_conditions formated with '\<br>' as line break
     * @type { String }
     */
    public get formated_additional_conditions () : string {
        if (!this.additional_conditions) {
            return ''
        }
        return this.additional_conditions.replace(/\n/g, '<br>')
    }

    /**
     * Returns true if proposal is expired
     */
    public get is_expired (): boolean {
        const today = moment()
        const expirationDate = moment(this.expiration_date, 'DD/MM/YYYY')
        let isExpiredValue = false

        if (this.status == ProposalStatus.g_lst_prop_status_sent || 
            this.status == ProposalStatus.g_lst_prop_status_revised ) 
        {
            
            const diff = expirationDate.diff(today, 'days')

            if (diff < 0) {
                isExpiredValue = true
            }
        }

        return isExpiredValue
    }

    /**
     * Returns true if proposal is expires in the coming 7 days
     */
    public get expires_soon () : boolean {
        const today = moment()
        const expirationDate = moment(this.expiration_date, 'DD/MM/YYYY')
        let expiresSoonValue = false

        if (this.status === ProposalStatus.g_lst_prop_status_sent || 
            this.status === ProposalStatus.g_lst_prop_status_revised ) 
        {
            
            const diff = expirationDate.diff(today, 'days')

            if ( diff >= 0 && diff <= 7) {
                expiresSoonValue = true
            }
        }

        return expiresSoonValue
    }

    /**
     * Returns version padded with 0
     */
    public get formated_version(): string {
        return pad(this.version!)
    }
}
