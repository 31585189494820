import { axios } from '@/services/axios/config'
import { useUserStore } from '@/stores/user'
import { IWork, IWorkImage, WorkDetailResponseType } from '@/models/interfaces/interfaces'

/**
 * API function to fetch list of works
 * @returns { Promise }
 */
export const getWorkList = async () => {
    const userStore = useUserStore()
    const user = userStore.getUser

    let payload = {
        "client_id": user.party_id,
        "status_list": 
            [
                "g_lst_work_status_pending",
                "g_lst_work_status_ongoing",
                "g_lst_work_status_terminated",
                "g_lst_work_status_cancelled"
            ],
        "paginated": false
    }

    const config = {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token.access}`
        }
    };

    let response = await axios.post('/works/work_list/?page=1&page_size=10', payload, config)
    return response.data.items as IWork[]
}

/**
 * API function to fetch work details
 * @returns { Promise }
 */
export const getWorkDetail = async (work_id: number) => {
    const userStore = useUserStore()
    const user = userStore.getUser

    const config = {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token.access}`
        }
    };

    const payload = {
        client_id: user.party_id,
        work_id: work_id
    }

    let response = await axios.post('/works/work_detail/', payload, config)
    return response.data as WorkDetailResponseType
}

interface payloadType {
    client_id?: number
    work_id?: number
    practical_info?: string | null
    client_reserves?: string
}

/**
 * API function to create a new work
 * @returns { Promise }
 */
export const updateWork = async (work_id: number, data: payloadType) => {
    const userStore = useUserStore()
    const user = userStore.getUser

    const config = {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token.access}`
        }
    };

    let payload: payloadType = {
        client_id: user.party_id,
        work_id: work_id
    }

    // only include practical info in the payload if it was actually
    // modified by the user
    if (Object.keys(data).includes('practical_info')) {
        payload.practical_info = data.practical_info
    }

    // only include client reserves in the payload if it was actually
    // modified by the user
    if (Object.keys(data).includes('client_reserves')) {
        payload.client_reserves = data.client_reserves
    }

    let response = await axios.post('/works/work_update/', payload, config)
    return response.data as WorkDetailResponseType
}

/**
 * API function to upload work image
 * @returns { Promise }
 */
export const upLoadWorkImage = async (work_id: number, data: any) => {
    const userStore = useUserStore()
    const user = userStore.getUser

    interface payloadType {
        client_id: number
        work_id: number
        account_id: number
        image?: Blob | File | null
    }

    const config = {
        headers:{
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${user.token.access}`
        }
    };

    let payload: payloadType = {
        client_id: user.party_id,
        work_id: work_id,
        account_id: user.account_id,
        image: data.image
    }

    let response = await axios.post('/works/work_image/', payload, config)
    return response.data as IWorkImage
}

/**
 * API function to delete work image
 * @returns { Promise }
 */
export const deleteWorkImage = async (work_id: number, data: any) => {
    const userStore = useUserStore()
    const user = userStore.getUser

    interface payloadType {
        work_id: number
        account_id: number
        image_id: number
    }

    let payload: payloadType = {
        work_id: work_id,
        account_id: user.account_id,
        image_id: data.image_id
    }

    const config = {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token.access}`
        },
        data: payload
    };

    let response = await axios.delete('/works/work_image/', config)
    return response.data
}

/**
 * API function execute work related actions
 * @example Cancellation, Abortion ...
 * @returns { Promise }
 */
export const workAction = async (work_id: number, data: any) => {
    const user = useUserStore().getUser

    interface payloadType {
        client_id: number
        account_id: number
        work_id: number
        target_action: string
        cancellation_reason: string
    }

    let payload: payloadType = {
        work_id: work_id,
        account_id: user.account_id,
        target_action: data.target_action,
        client_id: user.party_id,
        cancellation_reason: data.cancellation_reason,
    }


    const config = {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token.access}`
        },
    };

    let response = await axios.post('/works/work_action/', payload, config)
    return response.data
}

/**
 * API function to abort work early
 * @returns { Promise }
 */
export const abortWorkEarly = async (work_id: number, abortion_reason: string) => {
    const user = useUserStore().getUser

    interface payloadType {
        client_id: number
        account_id: number
        work_id: number
        request_action: string
        abortion_reason: string
    }

    let payload: payloadType = {
        work_id: work_id,
        account_id: user.account_id,
        request_action: "abort",
        client_id: user.party_id,
        abortion_reason: abortion_reason
    }


    const config = {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token.access}`
        },
    };

    let response = await axios.post('/works/work_action_request/', payload, config)
    return response.data
}

