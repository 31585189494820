/**
 * Represents the state management to track if a construct work
 * has changes
 */
import {defineStore} from "pinia";
import {computed, ref, Ref} from "vue";
import {WorkStatus} from "@/utils/enums.ts";
import {Work} from "@/models/Work.ts";
import {updateWork} from "@/services";
import {useAlertsStore} from "@/stores/alerts.ts";
import {navigate} from "vike/client/router";


const useWorkDetailStore = defineStore('workDetailStore', () => {
    const work: Ref<Work | null | undefined> = ref(null)
    const editable = ref(false)
    const practical_info_form_value: Ref<string> = ref('')
    const practical_info_save_loading = ref(false)
    const blockingPageLeave = ref(false)
    const onWorkDetailPage = ref(false)
    const alerts = useAlertsStore()

    const leaveWithoutSaving = ref(false)

    const clearData = () => {
        work.value = null
        editable.value = false
        practical_info_form_value.value = ''
        practical_info_save_loading.value = false
        blockingPageLeave.value = false
        onWorkDetailPage.value = false
    }

    const is_practical_info_editable = computed(() => {
        let isEditable = false
        if (
            work.value?.status === WorkStatus.g_lst_work_status_ongoing || work.value?.status === WorkStatus.g_lst_work_status_pending
        ) {
            if (work.value.practical_info && !editable.value) {
                isEditable = false
            } else {
                isEditable = true
            }
        } else {
            isEditable = false
        }

        return isEditable
    })

    const handleSaveChangesBeforeLeaving = async (pageContext: any) => {
        if (!blockingPageLeave.value && onWorkDetailPage.value) {
            if (window.event?.type === 'popstate') {
                if (!leaveWithoutSaving.value && practical_info_has_changes.value) {
                    const elem: any = document.getElementById('modalbutton');
                    elem?.click();
                    blockingPageLeave.value = true // set it to true to avoid infinite loop
                    await navigate(`/cs/workdetail/?id=${work.value?.work_id}&destination=${pageContext.urlPathname}`)
                } else {
                    clearData()
                }
            } else {
                if (!leaveWithoutSaving.value && practical_info_has_changes.value) {
                    const elem: any = document.getElementById('modalbutton');
                    elem?.click();
                    blockingPageLeave.value = true // set it to true to avoid infinite loop
                    await navigate(`/cs/workdetail/?id=${work.value?.work_id}&destination=${pageContext.urlPathname}`)
                } else {
                    clearData()
                }
            }
        }
      }

    const practical_info_has_changes = computed(() => {
        if (practical_info_form_value.value === work.value?.practical_info) {
            return false
        } else {
            return true
        }
    })

    const savePracticalInfoHandler = async () => {
        const payload = {
            practical_info: practical_info_form_value.value ? practical_info_form_value.value : null
        }

        try {
            practical_info_save_loading.value = true
            if (work.value && work.value.work_id) {
                const response = await updateWork(work.value.work_id, payload)
                if (work.value) {
                    work.value.practical_info = response.construct_work.practical_info
                    practical_info_form_value.value = work.value.practical_info
                    editable.value = !(work.value.practical_info)

                    alerts.putAlert({ 'code': 'saveRevisionSuccess', 'scope': 'general' })
                    practical_info_save_loading.value = false
                }
            }

            return Promise.resolve()
        } catch (error) {
            // console.error('error saving practical info', error)
            alerts.putAlert({ 'code': 'saveRevisionFail', 'scope': 'general' })
            practical_info_save_loading.value = false

            return Promise.reject()
        }
    }

    const onSavePracticalInfo = async () => {
        if (!practical_info_has_changes.value) {
            if (practical_info_form_value.value) editable.value = false
        } else {
            await savePracticalInfoHandler()
        }
    }


    return {
        work,
        editable,
        practical_info_form_value,
        practical_info_save_loading,
        is_practical_info_editable,
        onWorkDetailPage,
        savePracticalInfoHandler,
        onSavePracticalInfo,
        handleSaveChangesBeforeLeaving,
        clearData
    }
})
export default useWorkDetailStore