import { defineStore } from "pinia";
import { Proposal } from '@/models/Proposal'
import { IAddress, IProposal } from "@/models/interfaces/interfaces";

/**
 * Represents the state management for the current proposal
 */
export const useProposalDetailStore = defineStore('proposal', {
    state: () => ({
        proposal: new Proposal({} as IProposal)
    }),
    getters: {
        getProposal: (state) : Proposal => state.proposal as Proposal,
    },
    actions: {
        addProposal(proposal: Proposal) {
            this.proposal = proposal
        },
        updateAddress(address: IAddress, type: string) {
            if (type === 'client') {
                this.proposal.client.address = address
            } else if (type === 'worksite') {
                this.proposal.work_address = address
            } else if (type === 'billing') {
                this.proposal.billing_address = address
            }
        },
        createAddress(address: IAddress, type: string) {
            if (type === 'client__address') {
                this.proposal.client.address = address
            } else if (type === 'proposal__work_address') {
                this.proposal.work_address = address
            } else if (type === 'proposal__billing_address') {
                this.proposal.billing_address = address
            }
        }
    }
})

export default useProposalDetailStore