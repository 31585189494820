import {defineStore} from "pinia";
import {ref, Ref, computed} from "vue";
import {navigate} from "vike/client/router";
import {reviseProposal} from "@/services";
import useProposalDetailStore from "@/stores/proposalDetails.ts";
import {getUserFromLocalStorage} from "@/utils/functions.ts";
import {useAlertsStore} from "@/stores/alerts.ts";

/**
 * Represents the state managment to track if a proposal being revised
 * has changes
 */
const useItemUpdates = defineStore('proposalsItemUpdates', () => {
    const items: Ref<any[]> = ref([])
    const leaveWithoutSaving = ref(false)
    const onRevisionPage = ref(false)
    const saveBeforeLeaving = ref(false)
    const blockingPageLeave = ref(false)
    const saveLoading = ref(false)
    const proposalDetailStore = useProposalDetailStore()
    const user = getUserFromLocalStorage()
    const alerts = useAlertsStore()

    const getSize = computed(() => {
        return items.value.length
    })

    const addItems = (itemsList: any[]) => {
        items.value = itemsList
    }

    const clearData = () => {
        leaveWithoutSaving.value = false
        onRevisionPage.value = false
        blockingPageLeave.value = false
        saveBeforeLeaving.value = false
        saveLoading.value = false
        items.value = []
    }

    const saveChanges = () => {
        if(items.value.length === 0) {
            alerts.putAlert({'code': 'noChangesToSave', 'scope': 'general'})
        } else {
            saveLoading.value = true

            const payload = {
                "id": proposalDetailStore.getProposal.id ,
                "items": items.value,
                "send_revise": false
            }

            const config = {
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user?.token.access}`
                }
            }

            reviseProposal(payload, config)
                .then(() => {
                    // alerts.clearAlerts()
                    saveLoading.value = false
                    items.value = []

                    leaveWithoutSaving.value = false
                    blockingPageLeave.value = false
                    saveBeforeLeaving.value = false

                    alerts.putAlert({'code': 'saveRevisionSuccess', 'scope': 'general'})
                }).catch(() => {
                    // alerts.clearAlerts()
                    saveLoading.value = false
                    alerts.putAlert({'code': 'saveRevisionFail', 'scope': 'general'})
                })
        }
    }

    const handleSaveChangesBeforeLeaving = async (pageContext: any) => {
        if (items.value.length !== 0 && !blockingPageLeave.value && onRevisionPage.value) {
            if (window.event?.type === 'popstate') {
                if (!saveBeforeLeaving.value && !leaveWithoutSaving.value) {
                    const elem: any = document.getElementById('modalbutton');
                    elem?.click();
                    blockingPageLeave.value = true // set it to true to avoid infinite loop
                    await navigate(`/cs/proposal/revise/?id=${proposalDetailStore.getProposal.id}&destination=${pageContext.urlPathname}`)
                } else {
                    clearData()
                }
            } else {
                if (!saveBeforeLeaving.value && !leaveWithoutSaving.value) {
                    const elem: any = document.getElementById('modalbutton');
                    elem?.click();
                    blockingPageLeave.value = true // set it to true to avoid infinite loop
                    await navigate(`/cs/proposal/revise/?id=${proposalDetailStore.getProposal.id}&destination=${pageContext.urlPathname}`)
                } else {
                    clearData()
                }
            }
        }
    }

    return {
        items,
        leaveWithoutSaving,
        saveBeforeLeaving,
        getSize,
        onRevisionPage,
        saveLoading,
        clearData,
        addItems,
        saveChanges,
        handleSaveChangesBeforeLeaving
    }
})

export default useItemUpdates